export function patternValidators() {
    return {
        mobileNumberRegExp: '^[7-9].[0-9]{9}$',
        emailIdRegExp: '[a-zA-Z]+[a-zA-Z0-9._]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-]{2,5}\.[a-zA-Z]{2,4}',
        userNameRegExp: '^[a-zA-Z]+(( |-|_|@)?[a-zA-Z ]+)*$',
        empCodeRegExp: '^[a-zA-Z0-9\-]{2,20}',
        passwordRegExp: '^(?=.{6,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$',
        vehicleNoRegExo: '/^[a-z\d\-_\s]+$/i',
    }
}
