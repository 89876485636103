export class Verticals {
    public id: string;
    public name: string;
    public shortName: string;
    constructor(d: any = {}) {
        this.id = d._id || 0;
        this.name = d.name || 0;
        this.shortName = d.short_name || 0;
    }
}


export class Site {
    public id: string;
    public name: string;
    public siteCode: string;
    public city: string;
    public state: string;

    constructor(d: any = {}) {
        this.id = d._id || '';
        this.name = d.name || '';
        this.siteCode = d.site_code || '';
        this.city = d.city || '';
        this.state = d.state || ''
    }
}

export class formList {
    public id: string;
    public name: string;
    public vertical_id: string;

    constructor(d: any = {}) {
        this.id = d._id || '';
        this.name = d.name || '';
        this.vertical_id = d.vertical_id || '';
    }
}


export class ListFormsDynamic {
    public name: string;
    public createdDate: string;
    public id: string;
    constructor(d: any = {}) {
        this.name = d.register.name || d.register.associateName || '';
        this.createdDate = d.createddate || '';
        this.id = d._id || '';
    }
}

export class Otp {
    public otp1: number;
    public otp2: number;
    public otp3: number;
    public otp4: number;

    constructor(d: any = {}) {
        this.otp1 = d.otp1 || '';
        this.otp2 = d.otp2 || '';
        this.otp3 = d.otp3 || '';
        this.otp4 = d.otp4 || '';
    }
}