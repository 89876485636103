import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SessionStorage } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { SharedServiceService } from './utils';
import { ConnectionService } from 'ng-connection-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Digital-Registers-Project';
  @SessionStorage('auth') public user;
  public loading = true;
  public isConnected : any= true;

  constructor(
    public router: Router,
    public sharedService: SharedServiceService,
    private connectionService: ConnectionService,
    private changeDetector: ChangeDetectorRef
  ) {
    // check whether the user on online or offline
    this.isConnected = navigator.onLine;
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      this.changeDetector.detectChanges();
    });
  }



  ngOnInit() {
    // redirect to login if the token is not there
    if (!this.user) {
      this.router.navigateByUrl('/login');
    }

    this.sharedService.getDisplay().subscribe((val: boolean) => {
      this.loading = val;
      this.changeDetector.detectChanges();
    });
  }
}

