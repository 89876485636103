import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SessionStorage } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { Auth, SharedServiceService } from './utils';
import { ConnectionService } from 'ng-connection-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppCommonService } from './app-common/app-common.service';
import { KeycloakService } from 'keycloak-angular';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Digital-Registers-Project';
  @SessionStorage('auth') public user: any;
  @SessionStorage('authorized') public isAuthorized: boolean;
  @SessionStorage('refreshed') public isRefreshed: boolean;
  @SessionStorage('loader') public loader: boolean;
  public loading = false;
  public isConnected: any = true;

  constructor(
    public router: Router,
    public sharedService: SharedServiceService,
    private connectionService: ConnectionService,
    private changeDetector: ChangeDetectorRef,
    private commonService: AppCommonService,
    private keycloakService: KeycloakService,
    private snackBar: MatSnackBar
  ) {
    // check whether the user on online or offline
    this.isConnected = navigator.onLine;
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      this.changeDetector.detectChanges();
    });

  }

  ngOnInit(): void {
    if (this.isAuthorized) {
      if (this.isRefreshed) this.loading = true
      this.keycloakService.loadUserProfile().then(userProfile => {

        this.commonService.post('login', { keycloakid: userProfile.id, email: userProfile.email })
          .pipe(finalize(() => {
            this.loader = false
            this.isAuthorized = false
          }))
          .subscribe(response => {
            this.user = new Auth(response.payload);
            this.commonService.login.next(true)
            if (!this.isRefreshed) this.router.navigateByUrl('/dashboard')
          }, err => {
            this.keycloakService.logout()
            this.snackBar.open(err.hasOwnProperty('error') ? err.error.error.message : 'Could not connect to server', 'okay', { duration: 2000 });
          });
      }).catch(() => {
        this.loader = false
      })
    }
    if (!this.user?.userDetails?.email) {
      this.router.navigateByUrl('/login');
    }

    this.sharedService.getDisplay().subscribe((val: boolean) => {
      this.loading = val;
      this.changeDetector.detectChanges();
    });
  }
}