export class PaginationClass {
    public pageSize: number;
    public allPageSizes: Array<any>;
    public pageNumber: number;
    public totalPages: number;
    public totalUsers: number;
    searchText: any;
    constructor(public data: any = {}) {
        this.totalPages = data.hasOwnProperty('totalPages') ? data.totalPages : 0;
        this.pageSize = data.hasOwnProperty('pageSize') ? data.pageSize : 10;
        this.allPageSizes = [1, 5, 10, 25, 50, 100, 500];
        this.pageNumber = data.pageNumber || 1;
        this.searchText = data.searchText || '';
    }
}

/**
 * @class - holds pagination data
 * @member itemsPerPage - number of items to be shown in the page
 * @member currentPage - current page number
 */
export class PaginationControl {
    public autoHide: boolean;
    public maxSize: number;
    public responsive: boolean;
    public previousLabel: string;
    public nextLabel: string;
    searchText: any;

    constructor(d: any = {}) {
        this.autoHide = d.hasOwnProperty('autoHide') ? d.autoHide : true;
        this.maxSize = d.maxSize || 9;
        this.searchText = d.searchText || '';
        this.responsive = d.hasOwnProperty('responsive') ? d.responsive : true;
        this.previousLabel = d.previousLabel || '';
        this.nextLabel = d.nextLabel || '';
    }
}