import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// import { Headers, RequestOptions, Http, ResponseContentType } from '@angular/http';
import { SessionStorage } from 'ngx-webstorage';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceService {
  @SessionStorage('auth') public authToken: any;
  public baseUrl: string = environment.baseUrl;
  public serverError: EventEmitter<Response> = new EventEmitter();

  constructor(private http: HttpClient) { }

  /**
   * @method to perform the http `delete` method
   * @param api - name of the service/api to be called
   * @param data - data to be passed for deleting
   * @returns the Observable<any>
  */
  delete(api: any): Observable<any> {
    return this.http
      .delete(this.baseUrl + api,  {
        headers: this.getRequestHeaders()
      })
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  /**
   * @method to perform the http `get` method
   * @param api - name of the service/api to be called
   * @returns the Observable<any>
  */
  get(api: any): Observable<any> {
    return this.http
      .get(this.baseUrl + api, {
        headers: this.getRequestHeaders(true) , observe: "response" 
      })
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }




  /**
   * @method to perform the http `post` method
   * @param api - name of the service/api to be called
   * @param data - data to be passed to server
   * @returns the Observable<any>
  */
  post(api: any, data: any): Observable<any> {
    return this.http
      .post(this.baseUrl + api, JSON.stringify(data),{
        headers: this.getRequestHeaders(true)
      })
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  /**
 * @method to perform the http `post` method
 * @param api - name of the service/api to be called
 * @param data - data to be passed to server
 * @returns the Observable<any>
*/
  refreshPost(api: any, data: any): Observable<any> {
    return this.http
      .post(this.baseUrl + api, JSON.stringify(data), {
        headers: this.getRequestHeaders(true)
      })
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  /**
   * @method to perform the http `post` method
   * @param api - name of the service/api to be called
   * @param data - data to be passed to server
   * @returns the Observable<any>
  */
  upload(api: any, data: any): Observable<any> {

    const headers: HttpHeaders = new HttpHeaders();
    if (this.authToken) {
      headers.append('x-access-token', this.authToken.tokenType + ' ' + this.authToken.token);
      headers.append('platform', 'web');
    }
    return this.http.post(this.baseUrl + api, data, { headers: headers }).pipe(
      map(response => response), catchError(this.handleError));
  }

  /**
   * @method to perform the http `put` method
   * @param api - name of the service/api to be called
   * @param data - data to be passed for updating in server
   * @returns the Observable<any>
  */
  put(api: any, data: any): Observable<any> {
    return this.http
      .put(this.baseUrl + api, JSON.stringify(data), {
        headers: this.getRequestHeaders(true)
      }
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  /**
   * @method
   * @description
   * download the files
   */
  fileDownload(query: any) {
    return this.http.get(this.baseUrl + query , { headers: this.getFileUploadHeader(), responseType: "blob"})
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  /**
   * @method
   * @description
   * download the files based on the passed data
   */
  fileDataDownload(query: any, data: any) {
    return this.http.post(this.baseUrl + query, data, { headers: this.getFileUploadHeader(), responseType: "blob"})
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  /**
   * @method
   * @description
   * Request headers to be set during file upload
   */
  private getFileUploadHeader(): HttpHeaders {
    let fileUploadHeader: HttpHeaders;
    return fileUploadHeader = new HttpHeaders({
      'x-access-token': this.authToken.tokenType + ' ' + this.authToken.token,
      'platform': 'web'
    });
  }

  /**
   * @method to get the request headers
   * @param api - name of the service/api to be called
   * @param data - data to be passed for deleting
   * @returns the request headers of type `Headers`
  */
  // getRequestHeaders(): HttpHeaders {
  //   const headers: HttpHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'platform': 'web'
  //     // 'Connection': 'keep-alive',
  //     // 'Keep-Alive': 'timeout=200'   
  //   });
  //   // include the autherisation header if it exitsts
  //   if (this.authToken) {
  //     headers.set('x-access-token', this.authToken.tokenType + ' ' + this.authToken.token);
  //   }
  //   return headers;
  // }
  getRequestHeaders(addToken?: boolean) {
    let headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    if (this.authToken && this.authToken.tokenType && this.authToken.token && addToken) {
      headers = headers.append(
        "x-access-token",
        this.authToken.tokenType + " " + this.authToken.token
      );
    }
    
    return headers;
  }

  /**
 * @method to get the request headers
 * @param api - name of the service/api to be called
 * @param data - data to be passed for deleting
 * @returns the request headers of type `Headers`
*/
  getRefreshRequestHeaders(): HttpHeaders {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'platform': 'web'
      // 'Connection': 'keep-alive',
      // 'Keep-Alive': 'timeout=200'   
    });
    // include the autherisation header if it exitsts
    if (this.authToken) {
      headers.set('x-access-token', this.authToken.tokenType + ' ' + this.authToken.refreshToken);
    }
    return headers;
  }

  /**
   * @method to capture the service errors
   * @param api - name of the service/api to be called
   * @param data - data to be passed for deleting
  */
  handleError(error: any): Observable<any> {
    return throwError(error);
  }

  downloadReport() {
    
  }
}
