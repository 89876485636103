import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {
  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public counterSeconds = 60;
  
  constructor() { }

  display(value: boolean) {
    this.status.next(value);
  }

  getDisplay(): Observable<any> {
    return this.status.asObservable();
  }
}
